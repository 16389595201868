import { useCallback, useInsertionEffect, useRef } from 'react';

export const useEffectEvent = <TReturn>(fn: (...args: any[]) => TReturn) => {
	const ref = useRef<(...args: any[]) => TReturn>(fn);

	useInsertionEffect(() => {
		ref.current = fn;
	}, [fn]);

	return useCallback((...args: any[]) => {
		return ref.current.apply<null, any[], TReturn>(null, args);
	}, []);
};
