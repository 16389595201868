import type { CookieValue } from 'vanilla-cookieconsent';

declare const RocketChat: any;

const loadChatWidget = function (openOnLoad = false) {
	const script = document.createElement('script');
	const body = document.getElementsByTagName('body')[0]!;

	script.src = '/js/chat.js';
	script.id = 'rocketchat-widget-script';

	script.onload = function () {
		const chatIntervalId = setInterval(() => {
			if (RocketChat.livechat) {
				clearInterval(chatIntervalId);

				RocketChat.livechat.setTheme({
					fontColor: '#222',
				});

				if (openOnLoad) {
					RocketChat.livechat.maximizeWidget();
				}
			}
		}, 200);
	};

	body.append(script);
};

const removeChatWidget = function () {
	const iframe = document.getElementById('rocketchat-iframe');

	if (iframe) {
		iframe.remove();
	}

	const script = document.getElementById('rocketchat-widget-script');

	if (script) {
		script.remove();
	}
};

declare const dataLayer: any[];

const grantGoogleConsent = () => {
	function gtag() {
		// eslint-disable-next-line prefer-rest-params
		dataLayer.push(arguments);
	}

	// @ts-ignore ...
	gtag('consent', 'update', {
		ad_personalization: 'granted',
		ad_storage: 'granted',
		ad_user_data: 'granted',
		analytics_storage: 'granted',
	});

	const script = document.createElement('script');
	const body = document.getElementsByTagName('body')[0]!;

	script.async = true;
	script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16651577839';
	script.id = 'google-tag-manager-script';

	body.append(script);
};

export const cookieValuesChanged = function (cookie: CookieValue) {
	if (cookie.categories.includes('rocketchat')) {
		loadChatWidget();
	} else {
		removeChatWidget();
	}

	if (cookie.categories.includes('google-tag-manager')) {
		grantGoogleConsent();
	}
};
