/* eslint-disable perfectionist/sort-objects */
import type { Translation } from 'vanilla-cookieconsent';

const cookieTableHeaders = {
	name: 'Name',
	description: 'Beschreibung',
	duration: 'Dauer',
};

export const translations: Record<'de', Translation> = {
	de: {
		consentModal: {
			title: 'Wir verwenden Cookies',
			description:
				'Wir setzen Cookies ein, um Ihnen den bestmöglichen Service zu bieten. Bitte wählen Sie Ihre Präferenzen. Nähere Informationen finden Sie in unserer Datenschutzerklärung.',
			acceptAllBtn: 'Alle akzeptieren',
			acceptNecessaryBtn: 'Alle ablehnen',
			showPreferencesBtn: 'Einzelpräferenzen verwalten',
			footer: `
				<a href="/datenschutz" target="_blank">Datenschutzrichtlinien</a>
			`,
		},
		preferencesModal: {
			title: 'Cookie-Einstellungen verwalten',
			acceptAllBtn: 'Alle akzeptieren',
			acceptNecessaryBtn: 'Alle ablehnen',
			savePreferencesBtn: 'Aktuelle Auswahl akzeptieren',
			closeIconLabel: 'Modal schließen',
			serviceCounterLabel: 'Dienst|Dienste',
			sections: [
				{
					title: 'Ihre Datenschutz-Einstellungen',
					description: `In diesem Panel können Sie einige Präferenzen bezüglich der Verarbeitung Ihrer persönlichen Informationen ausdrücken. Sie können die getroffenen Auswahlmöglichkeiten jederzeit über den bereitgestellten Link überprüfen und ändern. Um Ihre Zustimmung zu den unten beschriebenen spezifischen Verarbeitungsaktivitäten zu verweigern, schalten Sie die Schalter aus oder verwenden Sie die Schaltfläche "Alle ablehnen" und bestätigen Sie, dass Sie Ihre Auswahl speichern möchten.`,
				},
				{
					title: 'Unbedingt erforderlich',
					description:
						'Diese Cookies sind für die ordnungsgemäße Funktion der Website unerlässlich und können nicht deaktiviert werden.',
					linkedCategory: 'necessary',
					cookieTable: {
						headers: cookieTableHeaders,
						body: [
							{
								name: 'cc_cookie',
								description: 'Speichert die Datenschutz-Einstellungen',
								duration: '12 Monate',
							},
							{
								name: 'mtm_consent_removed',
								description: 'Speichert die Ablehnung von Matomo',
								duration: '13 Monate',
							},
						],
					},
				},
				{
					title: 'Live-Chat',
					description:
						'Wir verwenden Rocket.Chat, um Ihnen die Möglichkeit eines Live-Chats mit uns anzubieten. Nach Ihrer Zustimmung finden Sie im unteren rechten Eck einen Chat-Button. Der Chat wird von uns selbst verwaltet, so dass keine Nutzerdaten an dritte weitergegeben werden müssen.',
					linkedCategory: 'rocketchat',
					cookieTable: {
						headers: cookieTableHeaders,
						body: [
							{
								name: 'rc_token',
								description: '',
								duration: 'Session',
							},
							{
								name: 'rc_uid',
								description: '',
								duration: 'Session',
							},
							{
								name: 'rc_room_type',
								description: '',
								duration: 'Session',
							},
							{
								name: 'rc_is_widget',
								description: '',
								duration: 'Session',
							},
						],
					},
				},
				{
					title: 'Umami',
					description:
						'Mithilfe von Umami erfassen wir statistische Angaben, die uns Aufschluss über die Verwendung unserer Webseite geben und uns helfen unser Angebot für Sie weiter zu verbessern. <br /><br />Die eingesetzte Lösung respektiert den Datenschutz und erfasst ausschließlich anonymisierte Daten.',
					linkedCategory: 'umami',
				},
				{
					title: 'Google Tag Manager',
					description: 'Wir verwenden den Google Tag Manager zur Erfolgsmessung unserer Anzeigen.',
					linkedCategory: 'google-tag-manager',
				},
			],
		},
	},
};
