'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { useEffectEvent } from '@/hooks/useEffectEvent';

declare const gtag: (
	targetId: string,
	type: string,
	params: {
		send_to: string;
	},
) => void;

export const GoogleAdsConversion = () => {
	const pathname = usePathname();

	const onMount = useEffectEvent(() => {
		if (pathname !== '/kostenloses-gespraech-vereinbart') {
			return;
		}

		gtag('event', 'conversion', { send_to: 'AW-16651577839/Bgq5CKfUjsUZEO_Ti4Q-' });
	});

	useEffect(() => {
		onMount();
	}, [onMount]);

	return null;
};
