'use client';

import { useEffect } from 'react';

import { useCanBodyScrollAtom } from '@/js/atoms';

type Props = {};

export const ScrollbarHelper = ({ ...props }: Props) => {
	const [canBodyScroll] = useCanBodyScrollAtom();

	useEffect(() => {
		const scrollbarWidth = window.innerWidth - document.body.offsetWidth;
		const html = document.documentElement;
		const body = document.body;

		if (canBodyScroll === false) {
			html.style.marginRight = `${scrollbarWidth}px`;
			body.style.overflowY = 'hidden';
		} else {
			html.style.marginRight = '';
			body.style.overflowY = '';
		}
	}, [canBodyScroll]);

	return null;
};
