'use client';

import { useEffect } from 'react';
import * as CC from 'vanilla-cookieconsent';

import { translations } from './translations';
import { cookieValuesChanged } from './util';

import 'vanilla-cookieconsent/dist/cookieconsent.css';

declare const window: {
	cookieConsent?: typeof CC;
};

if (typeof window !== 'undefined') {
	window.cookieConsent = CC;
}

export const CookieConsent = () => {
	useEffect(() => {
		CC.run({
			categories: {
				'google-tag-manager': {
					enabled: false,
				},
				necessary: {
					enabled: true, // this category is enabled by default
					readOnly: true, // this category cannot be disabled
				},
				rocketchat: {
					enabled: false,
				},
				umami: {
					enabled: true,
				},
			},
			guiOptions: {
				consentModal: {
					equalWeightButtons: false,
					flipButtons: true,
					layout: 'box wide',
					position: 'bottom center',
				},
				preferencesModal: {
					equalWeightButtons: false,
					flipButtons: true,
				},
			},
			language: {
				default: 'de',
				translations,
			},
			mode: 'opt-out',
			onChange: ({ cookie }) => {
				cookieValuesChanged(cookie);
			},
			onConsent: ({ cookie }) => {
				cookieValuesChanged(cookie);
			},
			onFirstConsent: ({ cookie }) => {
				cookieValuesChanged(cookie);
			},
			revision: 2,
		});
	}, []);

	return null;
};
