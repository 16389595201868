'use client';

import Script from 'next/script';

declare let Hyphenopoly: any;

export const HyphenopolyScript = () => {
	return (
		<Script
			onLoad={() => {
				Hyphenopoly.config({
					require: {
						de: 'Silbentrennungsalgorithmus',
					},
					setup: {
						selectors: {
							body: {},
						},
					},
				});
			}}
			src='/js/Hyphenopoly_Loader.js'
			strategy='lazyOnload'
		/>
	);
};
